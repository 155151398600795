<template>
  <div class="button-showcase-page">
    <div class="page-header">
      <h1>Button Components</h1>
      <p class="subtitle">Comprehensive guide to button styles, states, and usage patterns</p>
    </div>

    <div class="showcase-sections">
      <!-- Standard Button Types -->
      <div class="section">
        <h2>Standard Buttons</h2>
        <p class="section-description">Core button types used throughout the application</p>
        
        <div class="showcase-grid">
          <div class="showcase-item">
            <h3>Primary Button</h3>
            <div class="button-demo">
              <button class="primary_btn medium">Primary Action</button>
            </div>
            <p class="description">Used for main actions and important operations. Background: #5F6986, Hover: #6F7A99</p>
          </div>

          <div class="showcase-item">
            <h3>Secondary Button</h3>
            <div class="button-demo">
              <button class="secondary_btn medium">Secondary Action</button>
            </div>
            <p class="description">Alternative style. Background: #323659, Hover: #414366</p>
          </div>

          <div class="showcase-item">
            <h3>Tetriatary Button</h3>
            <div class="button-demo">
              <button class="tetriatary_btn medium">Cancel</button>
            </div>
            <p class="description">Outlined style. Border: #5F6986, Hover: rgba(#484a6c, .08)</p>
          </div>

          <div class="showcase-item">
            <h3>Call to Action</h3>
            <div class="button-demo">
              <button class="c2a_btn medium">Get Started</button>
            </div>
            <p class="description">Gradient background with shine effect. Used for key conversion points</p>
          </div>

          <div class="showcase-item">
            <h3>Red Button</h3>
            <div class="button-demo">
              <button class="red_btn medium">Delete</button>
            </div>
            <p class="description">Used for destructive actions. Background: #D21B1B, Hover: #E42B2B</p>
          </div>

          <div class="showcase-item">
            <h3>Arrow Button</h3>
            <div class="button-demo">
              <button class="c2a_btn medium">
                Next Step
                <span class="arrow">
                  <span class="line_svg"></span>
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                  </svg>
                </span>
              </button>
            </div>
            <p class="description">Button with animated arrow that extends on hover. Used for navigation and progression.</p>
          </div>

          <div class="showcase-item">
            <h3>Icon Button</h3>
            <div class="button-demo">
              <button class="primary_btn medium">
                <span class="icon" style="background-image: url('/icons/follow.svg');"></span>
                Follow
              </button>
            </div>
            <p class="description">Button with leading icon (20px). Used for actions with visual indicators.</p>
          </div>

          <div class="showcase-item">
            <h3>Fizz Effect</h3>
            <div class="button-demo">
              <button class="c2a_btn medium fizz-effect">
                Donate
                <div v-for="n in 8" :key="n" class="bubble-stream"></div>
              </button>
            </div>
            <p class="description">Button with animated bubble effect. Used for special call-to-action buttons.</p>
          </div>
        </div>
      </div>

      <!-- Button Sizes -->
      <div class="section">
        <h2>Size Variants</h2>
        <p class="section-description">Available size options for different contexts</p>
        
        <div class="size-variants">
          <div class="variant-row">
            <span class="label">X-Small</span>
            <div class="size-info">32px height, 24px padding, 13px font</div>
            <button class="primary_btn x_small">Button</button>
            <button class="secondary_btn x_small">Button</button>
            <button class="tetriatary_btn x_small">Button</button>
            <button class="c2a_btn x_small">Button</button>
          </div>
          
          <div class="variant-row">
            <span class="label">Small</span>
            <div class="size-info">40px height, 32px padding, 14px font</div>
            <button class="primary_btn small">Button</button>
            <button class="secondary_btn small">Button</button>
            <button class="tetriatary_btn small">Button</button>
            <button class="c2a_btn small">Button</button>
          </div>
          
          <div class="variant-row">
            <span class="label">Medium</span>
            <div class="size-info">48px height, 48px padding, 16px font</div>
            <button class="primary_btn medium">Button</button>
            <button class="secondary_btn medium">Button</button>
            <button class="tetriatary_btn medium">Button</button>
            <button class="c2a_btn medium">Button</button>
          </div>
          
          <div class="variant-row">
            <span class="label">Large</span>
            <div class="size-info">56px height, 64px padding, 18px font</div>
            <button class="primary_btn large">Button</button>
            <button class="secondary_btn large">Button</button>
            <button class="tetriatary_btn large">Button</button>
            <button class="c2a_btn large">Button</button>
          </div>

          <div class="variant-row">
            <span class="label">X-Large</span>
            <div class="size-info">64px height, 64px padding, 18px font</div>
            <button class="primary_btn x_large">Button</button>
            <button class="secondary_btn x_large">Button</button>
            <button class="tetriatary_btn x_large">Button</button>
            <button class="c2a_btn x_large">Button</button>
          </div>
        </div>
      </div>

      <!-- Button States -->
      <div class="section">
        <h2>States</h2>
        <p class="section-description">Different button states and interactions</p>
        
        <div class="states-grid">
          <div class="state-row">
            <span class="label">Default</span>
            <button class="primary_btn medium">Default State</button>
            <button class="secondary_btn medium">Default State</button>
            <button class="tetriatary_btn medium">Default State</button>
          </div>
          
          <div class="state-row">
            <span class="label">Hover</span>
            <button class="primary_btn medium hover">Hover State</button>
            <button class="secondary_btn medium hover">Hover State</button>
            <button class="tetriatary_btn medium hover">Hover State</button>
          </div>
          
          <div class="state-row">
            <span class="label">Active</span>
            <button class="primary_btn medium active">Active State</button>
            <button class="secondary_btn medium active">Active State</button>
            <button class="tetriatary_btn medium active">Active State</button>
          </div>
          
          <div class="state-row">
            <span class="label">Disabled</span>
            <button class="primary_btn medium" disabled>Disabled State</button>
            <button class="secondary_btn medium" disabled>Disabled State</button>
            <button class="tetriatary_btn medium" disabled>Disabled State</button>
          </div>
          
          <div class="state-row">
            <span class="label">Loading</span>
            <button class="primary_btn medium loading">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                color="white"
              ></v-progress-circular>
            </button>
          </div>
        </div>
      </div>

      <!-- Common Patterns -->
      <div class="section">
        <h2>Common Patterns</h2>
        <p class="section-description">Typical button combinations and usage patterns</p>
        
        <div class="patterns-grid">
          <div class="pattern-card">
            <h3>Dialog Actions</h3>
            <div class="pattern-demo dialog-actions">
              <button class="tetriatary_btn medium">Cancel</button>
              <button class="primary_btn medium">Confirm</button>
            </div>
            <p class="description">Standard dialog action buttons with 200px width on desktop</p>
          </div>

          <div class="pattern-card">
            <h3>Form Actions</h3>
            <div class="pattern-demo form-actions">
              <button class="tetriatary_btn medium">Reset</button>
              <button class="primary_btn medium">Save Changes</button>
            </div>
            <p class="description">Form submission actions with clear hierarchy</p>
          </div>

          <div class="pattern-card">
            <h3>Call to Action Group</h3>
            <div class="pattern-demo cta-group">
              <button class="tetriatary_btn medium">Learn More</button>
              <button class="c2a_btn medium">
                Get Started
                <span class="arrow">
                  <span class="line_svg"></span>
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 1L6.5 6L1.5 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </button>
            </div>
            <p class="description">High-emphasis actions with arrow animation on hover</p>
          </div>
        </div>
      </div>

      <!-- Mobile Responsiveness -->
      <div class="section">
        <h2>Responsive Behavior</h2>
        <p class="section-description">Drag the handle to resize and see how buttons adapt to different screen widths</p>
        
        <div class="responsive-preview">
          <div class="viewport-info">
            <span class="width-display">Width: <span class="value">375px</span></span>
            <span class="breakpoint-hint">&lt; 767px uses mobile styles</span>
          </div>

          <div class="resizable-container">
            <div class="device-frame">
              <div class="content">
                <!-- Dialog Pattern -->
                <div class="preview-section">
                  <h3>Dialog Pattern</h3>
                  <div class="dialog-actions">
                    <button class="tetriatary_btn">Cancel</button>
                    <button class="primary_btn">Confirm</button>
                  </div>
                </div>

                <!-- Full Width Pattern -->
                <div class="preview-section">
                  <h3>Full Width Pattern</h3>
                  <div class="stacked-actions">
                    <button class="primary_btn">Primary Action</button>
                    <button class="tetriatary_btn">Secondary Action</button>
                    <button class="c2a_btn">Call to Action</button>
                  </div>
                </div>

                <!-- Action Group Pattern -->
                <div class="preview-section">
                  <h3>Action Group Pattern</h3>
                  <div class="action-group">
                    <button class="primary_btn">Action 1</button>
                    <button class="tetriatary_btn">Action 2</button>
                    <button class="c2a_btn">Action 3</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="resize-handle"></div>
          </div>

          <div class="responsive-info">
            <div class="breakpoint">
              <span class="label">Mobile:</span>
              <span class="value">&lt; 767px</span>
              <span class="description">Small buttons (40px), full width, stacked layout</span>
            </div>
            <div class="breakpoint">
              <span class="label">Tablet:</span>
              <span class="value">768px - 1023px</span>
              <span class="description">Medium buttons (48px), adaptive width</span>
            </div>
            <div class="breakpoint">
              <span class="label">Desktop:</span>
              <span class="value">&gt; 1024px</span>
              <span class="description">Medium buttons (48px), fixed width</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonShowcase',
  metaInfo: {
    title: 'Button Components'
  },
  mounted() {
    this.initializeResizable();
    // Set initial responsive state
    const container = document.querySelector('.resizable-container');
    const widthDisplay = document.querySelector('.width-display .value');
    const initialWidth = 375;
    
    container.style.width = `${initialWidth}px`;
    widthDisplay.textContent = `${initialWidth}px`;
    container.classList.add('mobile');
  },
  methods: {
    initializeResizable() {
      const container = document.querySelector('.resizable-container');
      const handle = document.querySelector('.resize-handle');
      const widthDisplay = document.querySelector('.width-display .value');
      
      let isResizing = false;
      let startWidth, startX;

      handle.addEventListener('mousedown', (e) => {
        isResizing = true;
        startX = e.clientX;
        startWidth = container.offsetWidth;
        
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', () => {
          isResizing = false;
          document.removeEventListener('mousemove', handleMouseMove);
        });
      });

      const handleMouseMove = (e) => {
        if (!isResizing) return;
        
        const width = startWidth + (e.clientX - startX);
        const minWidth = 320; // Minimum width
        const maxWidth = 1200; // Maximum width
        
        if (width >= minWidth && width <= maxWidth) {
          container.style.width = `${width}px`;
          widthDisplay.textContent = `${width}px`;
          
          // Update responsive classes based on width
          container.classList.toggle('mobile', width < 768);
          container.classList.toggle('tablet', width >= 768 && width < 1024);
          container.classList.toggle('desktop', width >= 1024);
        }
      };
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/mixins/button-mixins";

.button-showcase-page {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  .page-header {
    margin-bottom: 3rem;
    text-align: center;

    h1 {
      color: #fff;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
    }
  }

  .section {
    margin-bottom: 4rem;

    h2 {
      color: #fff;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .section-description {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1rem;
      margin-bottom: 2rem;
    }
  }

  .showcase-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 1.5rem;

    .showcase-item {
      background: #282C47;
      border: 1px solid #353B49;
      border-radius: 12px;
      padding: 1.5rem;

      h3 {
        color: #fff;
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }

      .button-demo {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80px;
        background: rgba(3, 16, 32, 0.3);
        border-radius: 8px;
        padding: 1rem;
        margin-bottom: 1rem;

        button {
          margin: 0;
        }
      }

      .description {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.9rem;
        line-height: 1.4;
        margin: 0;
      }
    }
  }

  .size-variants {
    background: #282C47;
    border: 1px solid #353B49;
    border-radius: 12px;
    padding: 1.5rem;

    .variant-row {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      border-bottom: 1px solid rgba(95, 105, 134, 0.2);

      &:last-child {
        border-bottom: none;
      }

      .label {
        width: 80px;
        color: rgba(255, 255, 255, 0.9);
        font-size: 0.9rem;
        font-weight: 500;
      }

      .size-info {
        width: 220px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.85rem;
        font-family: monospace;
      }

      button {
        min-width: 120px;
      }
    }
  }

  .states-grid {
    background: #282C47;
    border: 1px solid #353B49;
    border-radius: 12px;
    padding: 1.5rem;

    .state-row {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      border-bottom: 1px solid rgba(95, 105, 134, 0.2);

      &:last-child {
        border-bottom: none;
      }

      .label {
        width: 100px;
        color: rgba(255, 255, 255, 0.9);
        font-size: 0.9rem;
        font-weight: 500;
      }

      button {
        min-width: 160px;

        &.hover {
          &.primary_btn {
            background: #6F7A99;
          }
          &.secondary_btn {
            background: #414366;
          }
          &.tetriatary_btn {
            background: rgba(#484a6c, .08);
            border-color: lighten(#5F6986, 10%);
          }
        }

        &.active {
          &.primary_btn {
            background: #818BA6;
          }
          &.secondary_btn {
            background: #484A6C;
          }
          &.tetriatary_btn {
            background: rgba(#484a6c, .12);
          }
        }
      }
    }
  }

  .patterns-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;

    .pattern-card {
      background: #282C47;
      border: 1px solid #353B49;
      border-radius: 12px;
      padding: 1.5rem;

      h3 {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        text-align: center;
      }

      .pattern-demo {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin: 1.5rem 0;

        button {
          min-width: 160px;
        }
      }

      .description {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.9rem;
        text-align: center;
        line-height: 1.5;
      }
    }
  }

  .responsive-preview {
    margin: 0 auto;

    .viewport-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      .width-display {
        font-family: monospace;
        color: rgba(255, 255, 255, 0.9);
        
        .value {
          color: #20C4F5;
        }
      }

      .breakpoint-hint {
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.85rem;
      }
    }

    .resizable-container {
      position: relative;
      width: 375px;
      min-width: 320px;
      max-width: 1200px;
      margin: 0 auto;
      transition: all 0.1s ease;

      // Add default styles for mobile view
      .dialog-actions {
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;
        
        button {
          flex: 1;
          height: 40px;
          font-size: 14px;
        }
      }

      .stacked-actions {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        margin-bottom: 1.5rem;

        button {
          width: 100%;
          height: 40px;
          font-size: 14px;
        }
      }

      .action-group {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        button {
          flex: 1;
          min-width: 120px;
          height: 40px;
          font-size: 14px;
        }
      }

      .device-frame {
        background: #282C47;
        border: 1px solid #353B49;
        border-radius: 12px;
        height: 500px;
        overflow-y: auto;

        .content {
          padding: 1.5rem;

          .preview-section {
            margin-bottom: 2rem;

            h3 {
              color: rgba(255, 255, 255, 0.7);
              font-size: 0.9rem;
              margin-bottom: 1rem;
            }

            .dialog-actions {
              display: flex;
              gap: 1rem;
              
              button {
                flex: 1;
                transition: all 0.3s ease;
              }
            }

            .stacked-actions {
              display: flex;
              flex-direction: column;
              gap: 0.75rem;

              button {
                width: 100%;
                transition: all 0.3s ease;
              }
            }

            .action-group {
              display: flex;
              flex-wrap: wrap;
              gap: 0.5rem;

              button {
                flex: 1;
                min-width: 120px;
                transition: all 0.3s ease;
              }
            }
          }
        }
      }

      .resize-handle {
        position: absolute;
        top: 0;
        right: -1rem;
        width: 2rem;
        height: 100%;
        cursor: ew-resize;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: '';
          width: 4px;
          height: 2rem;
          background: rgba(95, 105, 134, 0.5);
          border-radius: 2px;
          transition: background 0.2s ease;
        }

        &:hover:after {
          background: #20C4F5;
        }
      }

      // Responsive classes
      &.mobile {
        .primary_btn, .secondary_btn, .tetriatary_btn, .c2a_btn {
          height: 40px;
          font-size: 14px;
        }
      }

      &.tablet {
        .primary_btn, .secondary_btn, .tetriatary_btn, .c2a_btn {
          height: 48px;
          font-size: 16px;
        }
      }

      &.desktop {
        .primary_btn, .secondary_btn, .tetriatary_btn, .c2a_btn {
          height: 48px;
          font-size: 16px;
        }

        .dialog-actions button {
          min-width: 200px;
          flex: 0 1 auto;
        }
      }
    }

    .responsive-info {
      margin-top: 2rem;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      .breakpoint {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          width: 80px;
          color: rgba(255, 255, 255, 0.9);
          font-weight: 500;
        }

        .value {
          width: 140px;
          color: #20C4F5;
          font-family: monospace;
        }

        .description {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  // Icon button specific styles
  .icon {
    display: flex;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .fizz-effect {
    @include button-fizz-effect;
  }

  .arrow {
    display: flex;
    align-items: center;
    margin-left: 5px;

    .line_svg {
      display: flex;
      align-items: center;
      width: 7px;
      height: 2px;
      background-color: #fff;
      transform: translate(6px);
      opacity: 0;
      transition: all 0.2s;
    }

    svg {
      transition: all 0.25s;
    }
  }

  button:hover {
    .arrow {
      .line_svg {
        opacity: 1;
        transform: translate(6px);
      }
      svg {
        transform: translate(3px);
      }
    }
  }
}
</style> 